<template>
  <v-container fluid class="pa-0" tabindex="0"
               @keypress.ctrl.73.prevent.stop="print">
    <!-- P = 80 -->
    <v-row no-gutters>
      <v-col cols="2">
        <v-btn class="mb-4" @click="$router.go(-1)">
          {{ $t("actions.back") }}
        </v-btn>
      </v-col>
      <v-spacer></v-spacer>

      <v-btn @click="print()">
        <v-icon v-if="!loadingPrint">mdi-printer</v-icon>
        <v-progress-circular v-if="loadingPrint" size="16" color="primary" indeterminate ></v-progress-circular>
      </v-btn>

    </v-row>
    <v-card height="560" v-if="comLoaded">
      <v-toolbar>

          <v-toolbar-title>
            <span>
              {{ lang == "fr" ? news.label : news.label_en }}
            </span>
            <br>
            <span class="subtitle grey--text">
              {{ news.date ? new Date(news.date).toLocaleDateString() : "" }}
            </span>
          <v-chip x-small v-if="isAdmin" class="ml-4 rounded" :color="chipOnline(news).color">{{ chipOnline(news).text }}</v-chip>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="lang='fr'">
            <img height="16" :src="require('@/Assets/Images/Interface/Flags/flag-france.svg')" alt="flag-france">
          </v-btn>
          <v-btn icon @click="lang='en'">
            <img height="16" :src="require('@/Assets/Images/Interface/Flags/flag-england.svg')" alt="flag-england">
          </v-btn>
      </v-toolbar>

      <v-card-text class="fill-height pb-16">
        <div v-if="!loaded" class="d-flex flex-row align-center justify-center fill-height">
          <v-progress-circular indeterminate size="75" color="primary"></v-progress-circular>
        </div>
        <iframe id="comFrame" ref="comFrame" class="frame mb-12" :class="{dark}" width="100%" v-show="loaded" v-on:load="loaded=true" :src="lang == 'fr' ? news.news : news.news_en"></iframe>
        <br><br><br>
      </v-card-text>

    </v-card>
    <v-card v-else>
      <v-skeleton-loader type="card"></v-skeleton-loader>
    </v-card>
  </v-container>
</template>

<script>
import Vuex from "vuex"
import PlaneteOnlineService from "@/Services/PlaneteOnlineService";

export default {
  name: "Communication",
  props: {
    news: Object
  },
  computed: {
    ...Vuex.mapState(["informations"]),
    isAdmin() {
      return this.informations ? (this.informations.superAdministrator || false) : false
    },
    dark() {
      return this.$vuetify.theme.dark
    },
    // lang() {
    //   return this.$i18n.locale == "en" ? "en" : "fr"
    // }
    lang: {
      get() {
        return this.$i18n.locale == "en" ? "en" : "fr"
      },
      set(val) {
        this.$i18n.locale = val
      }
    }
  },
  data() {
    return {
      window,
      comLoaded: false,
      loaded: false,
      content: "",
      loadingPrint: false,
    }
  },
  methods: {
    print() {
      let url = this.$refs["comFrame"].getAttribute("src")
      window.open(url, '_blank');
    },
    chipOnline(com) {
      if (com.date && !(com.date instanceof Date)) {
        com.date = new Date(com.date)
      }
      if (com.expiration && !(com.expiration instanceof Date)) {
        com.expiration = new Date(com.expiration)
      }
      if (com.date) {
        let now = new Date()
        if (com.date > now) {
          return {
            color: "primary",
            text: "Diffusion le " + com.date.toLocaleDateString() + " à " + com.date.toLocaleTimeString()
          }
        } else {
          if (com.expiration && com.expiration <= now) {
            return {
              color: "warning",
              text: "Expirée"
            }
          } else {
            return {
              color: "success",
              text: "En ligne"
            }
          }
        }
      } else {
        return {
          color: "error",
          text: "Aucune date de diffusion"
        }
      }
    },
    detectPrintShortcut(e) { // Si CTRL + P => On modifie le print normal par le print de l'iframe
      if (!(e.keyCode === 80 && e.ctrlKey)) return

      e.preventDefault()
      this.print()
    }
  },
  async mounted() {
    if (this.news != null) {

      this.comLoaded = true
      this.$store.commit("readCom", { id: this.news._id })
      let store = this.$_.pick(this.$store.state, ["widgetBoard"])
      let response = await PlaneteOnlineService.postInterface(store)
    }

    document.addEventListener("keydown", this.detectPrintShortcut);
  },
  watch: {
    async news(newV, oldV) {
      if (newV != null) {

        this.comLoaded = true
        this.$store.commit("readCom", { id: newV._id })
        let store = this.$_.pick(this.$store.state, ["widgetBoard"])
        let response = await PlaneteOnlineService.postInterface(store)
      }
    }
  }
}
</script>

<style scoped>


  .frame {
    height: calc(100% - 3em);
    min-height: auto;
  }

  .subtitle {
    font-size: .6em;
  }

  iframe.dark table {
    background: #1E1E1E !important;
  }
</style>